/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
body {
  background-color: rgb(249, 250, 251);
}

:root {
  --form-control-color: #111;
  --form-background: #fff;
  --header-background: #111;
  --neutral-white:#fff;
  --white:#fff;
  --grey-200:#f3f3f3;
  --grey-400:#ccc;
  --grey-500:#9B9B9B;
  --grey-600:#808080;
  --secondary-bg-modal:rgba(45, 49, 46, 0.80);
  --primary-green-400:#1EAA43;
  --primary-green-200:#59D87A;
  --primary-green-100:#8CF5A8;
  --secondary-progress-bar-bg:rgba(193, 255, 210, 0.20);
  --bg-background:rgba(252, 252, 252, 0.20);
  --secondary-button-bg-secondary:#444A46;
  --red:#EA2B2B;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Lato', sans-serif;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}